import React, {useContext} from "react"
import NavItem from "../NavItem"
import { AppContext } from "../../../appContext"
import NavBarData from "../../../Data/NavBarData"

function MobileNavItems(props) {
    const {appState} = useContext(AppContext) 
    const navClassName = props.visible ? "mobileNavItems" : "hidden"
    const navItems = NavBarData.map( (item) => {
        return(
            <NavItem
                key={item.itemEng}
                text={appState.lang==="eng" ? item.itemEng : item.itemCym}
                link={item.link}
                visible = {props.visible}
                toggle={props.toggle}
            />
        )
    })

    return(
        <div className={navClassName}>
            {navItems}
        </div>
    )
}

export default MobileNavItems