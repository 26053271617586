import React from "react"
import NavItem from "./NavItem"

function NavTitle(props){
    const styleName = props.isMobile ? "mobNavTitle" : "deskNavTitle"
    const title = props.isMobile ? "Joe Wright Music" : <NavItem text={"Joe Wright Music"} link={"/"} visible = {true} toggle={props.toggle}/>
    return(
        <div className={styleName}>
            <h1>
                {title}
            </h1>
        </div>
    )
}

export default NavTitle