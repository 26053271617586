import React from "react"

function ContentBdr(props) {

    const style1a = {
        borderTopRightRadius: `${3+ Math.random()*50}vw ${1+Math.random()*2000}vh`,
        borderTopLeftRadius: `${1+ Math.random()*2000}vw ${3+Math.random()*50}vh`,
        borderBottomRightRadius: `${3+ Math.random()*50}vw ${1+Math.random()*2000}vh`,
        borderBottomLeftRadius: `${1+ Math.random()*2000}vw ${3+Math.random()*50}vh`,
        borderTop: `${1 + Math.random()*5}px solid black`,
        borderBottom: `${1 + Math.random()*5}px solid black`,
        borderLeft: `${1 + Math.random()*5}px solid black`,
        borderRight: `${1 + Math.random()*5}px solid black`,
        // width: props.dim,
        // height: props.dim
    }
    const style1b = {
        borderTopRightRadius: `${1+Math.random()*50}vw ${3+Math.random()*2000}vh`,
        borderTopLeftRadius: `${3+Math.random()*2000}vw ${1+Math.random()*50}vh`,
        borderBottomRightRadius: `${1+Math.random()*50}vw ${3+Math.random()*2000}vh`,
        borderBottomLeftRadius: `${3+Math.random()*2000}vw ${1+Math.random()*50}vh`,
        borderTop: `${1 + Math.random()*5}px solid black`,
        borderBottom: `${1 + Math.random()*5}px solid black`,
        borderLeft: `${1 + Math.random()*5}px solid black`,
        borderRight: `${1 + Math.random()*5}px solid black`,
        // width: props.dim,
        // height: props.dim
    }
    
    let style = Math.floor(Math.random *2) === 1 ? style1a : style1b
    return (
        <div className="contentBdrOuter" >
            <div className="contentBdr" style={style}>
                {props.children}
            </div>
        </div>
    )
}

export default ContentBdr