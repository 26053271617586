import React, {useContext, useState} from "react"
import MediaInterpreter from "../MainElements/MediaInterpreter"
import FullWidthH1 from "../../Generic/FullWidthH1"
import discog from "../../../Data/Discog"
import { AppContext } from "../../../appContext"



function Work(){
    const [filters, setFilters] = useState({
        all: true,
        mus: false, 
        vids: false,
        code: false,
        collab: false,
    })

    function allButReset(){
        setFilters(prevFilters => ({
            all: true,
            mus: false, 
            vids: false,
            code: false,
            collab: false,
        }))
    }
    function flipMus(){
        setFilters(prevFilters => ({
            ...prevFilters,
            all: false,
            mus: !prevFilters.mus
        }))
    }
    function flipVids(){
        setFilters(prevFilters => ({
            ...prevFilters,
            all: false,
            vids: !prevFilters.vids,
        }))
    }
    function flipCode(){
        setFilters(prevFilters => ({
            ...prevFilters,
            all: false,
            code: !prevFilters.code
        }))
    }
    function flipCollab(){
        setFilters(prevFilters => ({
            ...prevFilters,
            all: false,
            collab: !prevFilters.collab
        }))
    }

    const styleSelected = {
        fontSize: "0.75rem",
        fontWeight: "bold",
        color: "black"
    }
    const styleNotSelected = {
        fontSize: "0.75rem",
        fontWeight: "normal",
        color: "grey"
    }
    const styleAll = filters.all ? styleSelected : styleNotSelected
    const styleMus = filters.mus ? styleSelected : styleNotSelected
    const styleVids = filters.vids ? styleSelected : styleNotSelected
    const styleCode = filters.code ? styleSelected : styleNotSelected
    const styleCollab = filters.collab ? styleSelected : styleNotSelected

    let filteredDiscog = []
    let filterAdditions 
    if(filters.all){
        filteredDiscog.push(...discog)
    } else { 
        if(filters.mus){
            filterAdditions = discog.filter(item => {
                return item.filter==="mus"
            })
            filteredDiscog.push(...filterAdditions)
        }
        if(filters.vids){
            filterAdditions = discog.filter(item => {
                return item.filter==="vids"
            })
            filteredDiscog.push(...filterAdditions)
        }
        if(filters.code){
            filterAdditions = discog.filter(item => {
                return item.filter==="code"
            })
            filteredDiscog.push(...filterAdditions)
        }
        if(filters.collab){
            filterAdditions = discog.filter(item => {
                return item.filter==="collab"
            })
            filteredDiscog.push(...filterAdditions)
        }
    }


    const {appState} = useContext(AppContext) 
    let dim
    if(appState.inWidth<1000){
        dim=150
    } else if (appState.inWidth<1500){
        dim=200
    } else {
        dim=250
    } 

    const date = new Date();
    const curYear = date.getFullYear();
    let year=curYear
    let output=[]
    while(year>2006){
        let yearHeading = {
            itemTitle: year.toString(),
            itemDate: year,
            type: "dateHeading",
        }
        let yearLineBreak = {
            itemTitle: year.toString(),
            itemDate: year+0.1,
            type: "flexLineBreak"
        }
        let yearCompare = year
        let yearItems = filteredDiscog.filter(item => {
            return parseInt(item.itemDate.toString().slice(0,4))===yearCompare
        })
        if(yearItems.length>0){
            output.push(yearHeading)
            output.push(...yearItems)
            // if(appState.isMobileView){
            output.push(yearLineBreak)
            // }
        }
        year=year-1 
    }

    const pageTitle = appState.lang === "eng" ? "Work" : "Gwaith"
    

    return(
        <main>
            <FullWidthH1 title={pageTitle}/>
            <div className="filters">
                <p style={styleNotSelected}>{appState.lang === "eng" ? "Show:" : "Dangos:"}</p>
                <button 
                    className="filtAll" 
                    onClick={(event) => allButReset()}
                    style={styleAll}
                >
                    <p>{appState.lang === "eng" ? "All" : "I gyd"}</p>
                </button>
                <button 
                    className="filtOthers" 
                    onClick={(event) => flipMus()}
                    style={styleMus}
                >
                    <p>{appState.lang === "eng" ? "Music" : "Cerddoriaeth"}</p>
                </button>
                <button 
                    className="filtOthers" 
                    onClick={(event) => flipVids()}
                    style={styleVids}
                >
                    <p>{appState.lang === "eng" ? "Video" : "Fideos"}</p>
                </button>
                <button 
                    className="filtOthers" 
                    onClick={(event) => flipCode()}
                    style={styleCode}
                >
                    <p>{appState.lang === "eng" ? "Code" : "Côd"}</p>
                </button>
                <button 
                    className="filtOthers" 
                    onClick={(event) => flipCollab()}
                    style={styleCollab}
                >
                    <p>{appState.lang === "eng" ? "Collaborations" : "Cydweithrediadau"}</p>
                </button>
            </div>
            <MediaInterpreter items={output} dim={dim} />
        </main>
    )
}

export default Work

