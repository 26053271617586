import React from "react"
import BandcampLong from "../../Generic/BandcampLong";
import ContentBdr from "../../Generic/ContentBdr";

function TextInterpreter(props){

    let count=0;
    const textBlock = props.items.map( item => {
        let rtnItem
        if(item.type === "h3"){
            count++
            rtnItem = (
                <h3 key={count} className="textSectionH3">
                    {item.title}
                </h3>
            )
        }
        else if(item.type === "p"){
            count++
            rtnItem = (
                <p key={count} className="textSectionP">
                    {item.paragraph}
                </p>
            ) 
        }
        else if(item.type === "h3C"){
            count++
            rtnItem = (
                <h3 key={count} className="textSectionH3 centered">
                    {item.title}
                </h3>
            )
        }
        else if(item.type === "pC"){
            count++
            rtnItem = (
                <p key={count} className="textSectionP centered">
                    {item.paragraph}
                </p>
            ) 
        }
        else if(item.type ==="bandcamp"){
            count++
            rtnItem = (
                <ContentBdr key={count} leftBorder={false} hideBorder={false}>
                    <BandcampLong 
                        albumNo={item.albumNo} 
                    />
                </ContentBdr>
            )
        }
        else {
            count++
            rtnItem = <div key={count}></div>
        }
        return(rtnItem)
   })
       

    return(
        <div className="textSection">
            {textBlock}
        </div>
    )
}

export default TextInterpreter