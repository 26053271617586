import React, {useState} from "react"
import MobileNavItems from "./Mobile/MobileNavItems"
import MobileLang from "./Mobile/MobileLang"
import NavTitle from "./NavTitle"
import Hamburger from "./Mobile/Hamburger"

function MobileNavBar(props) {
    const [expanded, setExapanded] = useState(false)
    const [isFirstTog, setIsFirstTog] = useState(true)

    function toggleMenu() {
        setExapanded(prevExpanded => !prevExpanded)
        setIsFirstTog(false)
    }
    return(
        <nav className="mobileNavBar">
            <NavTitle isMobile={true}/>
            <Hamburger toggle={toggleMenu} expanded={expanded} first={isFirstTog} />
            <MobileLang />
            <MobileNavItems visible={expanded} toggle={toggleMenu} />
        </nav>
    )
}

export default MobileNavBar