//add  elen solo release 2020
const discog = [
//2024
    // {
    //     itemTitle: "Owl Breath",
    //     artist: "Onin",
    //     type: "bandcamp",
    //     itemDate: 202409,
    //     albumNo: 2939686905,
    //     filter: "mus"
    // },
    {
        itemTitle: "Hidden Kingdoms",
        artist: "Birmingham Open Media",
        itemDate: 20240501,//year-month
        type: "imgLink",
        img: "HiddenKingdoms",
        link: "https://www.hiddenkingdoms.org/",
        filter: "collab"
    }, 
    {
        itemTitle: "Ricercare una Melodia - Max Patch",
        artist: "Jonathan Harvey",
        itemDate: 20240402,//year-month
        type: "imgLink",
        img: "RUM-harvey",
        link: "https://github.com/BirminghamConservatoire/Harvey-RicercareUnaMelodia",
        filter: "code"
    },    
    {
        itemTitle: "FORJ - One and the Same",
        artist: "FORJ",
        itemDate: 20240401,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "videoseries?si=EF9ezh7XSiABbj5K&amp;list=PL64RDmHqWQAqQIctQqVfOXekcZX-2N86k",
        filter: "vids"
    },

//2023
    {
        itemTitle: "Dahab Days",
        artist: "Rob Luft",
        type: "bandcamp",
        itemDate: 202310,
        albumNo: 3153088282 ,
        filter: "mus"
    },
    {
        itemTitle: "Sound Walk",
        artist: "Nature By The Taff",
        type: "imgLink",
        itemDate: 202309,
        img: "NBTTsoundWalk",
        link: "https://www.facebook.com/groups/naturebythetaff/permalink/2041382626223399/",
        filter: "collab"
    }, 
    {
        itemTitle: "Out of the Box",
        artist: "Daniel Naddafy",
        type: "imgLink",
        itemDate: 202305,
        img: "OutOfTheBox",
        link: "https://www.danielnaddafy.com/out-of-the-box",
        filter: "collab"
    }, 
    {
        itemTitle: "Synesthesia",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20230402,
        img: "Synesthesia",
        link: "https://synesthesia.integra.io/",
        filter: "code"
    }, 
    {
        itemTitle: "SOGgy",
        artist: "Onin",
        type: "bandcamp",
        itemDate: 202304,
        albumNo: 374984259 ,
        filter: "mus"
    },
    {
        itemTitle: "Space To Be",
        artist: "Oily Cart",
        type: "imgLink",
        itemDate: 202303,
        img: "Space2Be",
        link: "https://oilycart.org.uk/shows/space-to-be-hospice-version/",
        filter: "collab"
    }, 


//2022
    {
        itemTitle: "Sun Swells",
        artist: "Corrie Dick",
        type: "bandcamp",
        itemDate: 202210,
        albumNo: 1194205044,
        filter: "mus"
    },
    {
        itemTitle: "We Deliver",
        artist: "Daniel Patric Cohen",
        type: "bandcamp",
        itemDate: 20220902,
        albumNo: 3917871782,
        filter: "mus"
    },
    {
        itemTitle: "Sad DJ",
        artist: "Joe Wright",
        type: "bandcamp",
        itemDate: 20220901,
        albumNo: 3160389612,
        filter: "mus"
    },
    {
        itemTitle: "Sound Symphony",
        artist: "Oily Cart",
        type: "imgLink",
        itemDate: 20220402,
        img: "SoundSymphony",
        link: "https://oilycart.org.uk/shows/sound-symphony/",
        filter: "collab"
    }, 
    {
        itemTitle: "Our Space",
        artist: "FORJ",
        type: "bandcamp",
        itemDate: 20220401,
        albumNo: 4101909796,
        filter: "mus"
    },
    {
        itemTitle: "Contour",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20220102,
        img: "Contour",
        link: "https://github.com/JoeWrightMusic/Contour",
        filter: "code"
    }, 
    {
        itemTitle: "Touch Oboe and Touch Trombone",
        artist: "Drake Music and Orch Lab",
        type: "imgLink",
        itemDate: 20220101,
        img: "DMtouch",
        link: "https://www.drakemusic.org/technology/instruments-projects/orchlab-instruments-touch-trombone-oboe/",
        filter: "collab"
    }, 
    
//2021
    {
        itemTitle: "Skirfare",
        artist: "FORJ",
        type: "imgLink",
        itemDate: 202102,
        img: "Skirfare",
        link: "https://www.forj4tet.co.uk/skirfare",
        filter: "mus"
    },  
    {
        itemTitle: "Norse2DynamicFilter",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20211102,
        img: "Norse2DynamicFilter",
        link: "https://github.com/JoeWrightMusic/Norse2DynamicFilter",
        filter: "code"
    }, 
    {
        itemTitle: "Chord Convolver",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20211101,
        img: "ChordConvolver",
        link: "https://github.com/JoeWrightMusic/ChordConvolver",
        filter: "code"
    }, 
    {
        itemTitle: "Partial Playground",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20211002,
        img: "PartialPlayground",
        link: "https://github.com/JoeWrightMusic/PartialPlayground",
        filter: "code"
    },  
    {
        itemTitle: "Reverse Tap Delay",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20211001,
        img: "ReverseTapDelay",
        link: "https://github.com/JoeWrightMusic/IntegraReverseDelayTap",
        filter: "code"
    },
    {
        itemTitle: "Space To Be",
        artist: "Oily Cart",
        type: "imgLink",
        itemDate: 202103,
        img: "Space2Be",
        link: "https://oilycart.org.uk/shows/space-to-be-2/",
        filter: "collab"
    }, 
//2020
    {
        itemTitle: "QuickNDirtyFaustSynths",
        artist: "Joe Wright",
        type: "imgLink",
        itemDate: 20201001,
        img: "QuickNDirtyFaustSynths",
        link: "https://github.com/JoeWrightMusic/QuickNDirtyFaustSynths",
        filter: "code"
    },
    {
        itemTitle: "Yn Y Cyfnod Clo",
        artist: "Elen Hydref",
        itemDate: 2020100,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2035847514",
        filter: "mus"
    },
    {
        itemTitle: "Soliloquy",
        artist: "Joe Wright",
        itemDate: 202009,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1406202218",
        filter: "mus"
    },
    {
        itemTitle: "Impossible Remixes",
        artist: "Corrie Dick",
        itemDate: 202005,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "294417408",
        filter: "mus"
    },
    {
        itemTitle: "Life is the Dancer",
        artist: "Rob Luft",
        itemDate: 2020041,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1598272248",
        filter: "mus"
    }, 
    {
        itemTitle: "Compositions I",
        artist: "Sorana Santos",
        itemDate: 202004,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2090242645",
        filter: "mus"
    },
    {
        itemTitle: "O1N1I1U1",
        artist: "Onin",
        itemDate: 202003,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "3966218909",
        filter: "mus"
    },
//2019
    {
        itemTitle: "non",
        artist: "Onin",
        itemDate: 201908,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "225157356",
        filter: "mus"
    },
    {
        itemTitle: "Saxophone & Dynamic Feedback Solos",
        artist: "Joe Wright",
        itemDate: 201902,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "3557820557",
        filter: "mus"
    },
    {
        itemTitle: "Sound Symphony",
        artist: "Oily Cart",
        type: "imgLink",
        itemDate: 201906,
        img: "SoundSymphony",
        link: "https://oilycart.org.uk/shows/sound-symphony/",
        filter: "collab"
    }, 
//2018
    {
        itemTitle: "SICUM AT-EM",
        artist: "Onin",
        itemDate: 2018101,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2230370983",
        filter: "mus"
    },
    {
        itemTitle: "Irridescent Strand",
        artist: "Chrysakis, Lynch, O'Sullivan, & Wright",
        type: "imgLink",
        itemDate: 201810,
        img: "IrridescentStrand",
        link: "https://www.auralterrains.com/releases/39",
        filter: "mus"
    },
    {
        itemTitle: "iField Recordings 1",
        artist: "Joe Wright",
        itemDate: 201808,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "570556392",
        filter: "mus"
    },
//2017
    {
        itemTitle: "Lucent",
        artist: "duck-rabbit",
        itemDate: 201710,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2797614895",
        filter: "mus"
    }, 
    {
        itemTitle: "Yarrow II - ClariNot Solos",
        artist: "Joe Wright",
        itemDate: 201708,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "41111320",
        filter: "mus"
    },
    {
        itemTitle: "Crimes Against Common Sense",
        artist: "Joe Wright and James L Malone with the SKRONKESTRA",
        itemDate: 20170731,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "4117069870",
        filter: "mus"
    },  
    {
        itemTitle: "Riser",
        artist: "Rob Luft",
        itemDate: 2017073,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "4191199789",
        filter: "mus"
    },  
    {
        itemTitle: "Onin - Errery Album Launch @ I K L E C T I K",
        artist: "Onin",
        itemDate: 2017072,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "V90epEcSE7Y",
        filter: "vids"
    }, 
    {
        itemTitle: "Errery",
        artist: "Onin",
        itemDate: 2017071,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "532479034",
        filter: "mus"
    },
    {
        itemTitle: "Live in Oxford",
        artist: "duck-rabbit",
        itemDate: 201701,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "3359580522",
        filter: "mus"
    },   
//2016
    {
        itemTitle: "Fragment",
        artist: "Jonathan Silk Big Band",
        itemDate: 201609,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "859073380",
        filter: "mus"
    },
    {
        itemTitle: "Solo Three of Three @ IKLECTIK",
        artist: "Joe Wright",
        itemDate: 2016093,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "RnVUt99DqIE",
        filter: "vids"
    }, 
    {
        itemTitle: "Solo Two of Three @ IKLECTIK",
        artist: "Joe Wright",
        itemDate: 2016092,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "JSc_ihmF3hc",
        filter: "vids"
    }, 
    {
        itemTitle: "Solo One of Three @ IKLECTIK",
        artist: "Joe Wright",
        itemDate: 2016091,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "Z8z9L3RIYAE",
        filter: "vids"
    }, 
    {
        itemTitle: "Le Cheval Solitaire",
        artist: "aboutNOWish",
        type: "imgLink",
        itemDate: 201606,
        img: "ChevalSolitaire",
        link: "https://withoutwalls.uk.com/programme/lechevalsolitaire/",
        filter: "collab"
    }, 
//2015
    {
        itemTitle: "Flounder",
        artist: "duck-rabbit",
        itemDate: 2015121,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "feOF8OuXLPk",
        filter: "vids"
    },  
    {
        itemTitle: "Scattered Voices: Part IV",
        artist: "duck-rabbit",
        itemDate: 201512,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "4149855697",
        filter: "mus"
    },
    {
        itemTitle: "Under Foot",
        artist: "aboutNOWish",
        type: "imgLink",
        itemDate: 2015113,
        img: "uFoot",
        link: "https://www.dotlib.org/blog/2015/11/26/underfoot-performance",
        filter: "collab"
    }, 
    {
        itemTitle: "Yarrow",
        artist: "Joe Wright",
        itemDate: 2015112,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "40222183",
        filter: "mus"
    },
    {
        itemTitle: "Impossible Things",
        artist: "Corrie Dick",
        itemDate: 201511,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2847044012",
        filter: "mus"
    },
    {
        itemTitle: "Butterflies and Dragons",
        artist: "TROPE",
        itemDate: 201510,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2474454963",
        filter: "mus"
    },
    {
        itemTitle: "Accretion",
        artist: "duck-rabbit",
        itemDate: 2015061,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1754050627",
        filter: "mus"
    },
    {
        itemTitle: "Our Lady of Stars",
        artist: "Sorana Santos",
        itemDate: 201506,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "789295795",
        filter: "mus"
    },
    {
        itemTitle: "Flow",
        artist: "duck-rabbit",
        itemDate: 2015041,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "xzhE6T-AeWA",
        filter: "vids"
    },
    {
        itemTitle: "Scattered Voices: Part III",
        artist: "duck-rabbit",
        itemDate: 201504,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1109021864",
        filter: "mus"
    },
//2014
    {
        itemTitle: "Stratigraphy",
        artist: "Joe Wright",
        itemDate: 201412,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "361874676",
        filter: "mus"
    },
    {
        itemTitle: "Shackled",
        artist: "duck-rabbit",
        itemDate: 2014091,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "UgjJU53jNSg",
        filter: "vids"
    },
    {
        itemTitle: "Scattered Voices: Part II",
        artist: "duck-rabbit",
        itemDate: 201409,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "2636305127",
        filter: "mus"
    },
    {
        itemTitle: "Slumber",
        artist: "duck-rabbit",
        itemDate: 2014061,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "Ryp95bF3H-8",
        filter: "vids"
    },
    {
        itemTitle: "Scattered Voices: Part I",
        artist: "duck-rabbit",
        itemDate: 201406,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1660870391",
        filter: "mus"
    },
    {
        itemTitle: "Gates",
        artist: "Nightjar",
        itemDate: 2014041,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "bpKfy8NPHKU",
        filter: "vids"
    },
    {
        itemTitle: "Path to Field",
        artist: "duck-rabbit",
        itemDate: 201404,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1003166666",
        filter: "mus"
    },
    {
        itemTitle: "The Passenger",
        artist: "Daniel Patric Cohen",
        itemDate: 201401,
        type: "imgLink",
        link: "https://open.spotify.com/album/1eM5p1NpBv0bnBwrGUQMSX?go=1&sp_cid=4dfb6524820d4c8ff0c873433770e3f7&utm_source=embed_player_m&utm_medium=desktop&nd=1",
        img: "ThePassenger",
        filter: "mus"
    },
//2013
    {
        itemTitle: "Strange Places",
        artist: "Nightjar",
        itemDate: 201308,//year-month
        type: "youtube",//bandcamp, imgLink, youtube
        video: "zY1H14-y0aE",
        filter: "vids"
    },
    {
        itemTitle: "Live @ The Vortex",
        artist: "Stories",
        itemDate: 201311,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1351309876",
        filter: "mus"
    },
    {
        itemTitle: "Between Shadows",
        artist: "Reuben Fowler",
        itemDate: 2013071,
        type: "imgLink",
        link: "https://open.spotify.com/album/5AQkYoyik0T7SYDQsK0mDC",
        img: "BetweenShadows",
        filter: "mus"
    },
    {
        itemTitle: "Uncouth",
        artist: "Jonathan Silk Big Band",
        itemDate: 201307,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1811423553",
        filter: "mus"
    },
    {
        itemTitle: "Stories",
        artist: "Stories",
        itemDate: 201303,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "354765934",
        filter: "mus"
    },
    {
        itemTitle: "Light at Night",
        artist: "Dave Hamblett",
        itemDate: 201302,//year-month
        type: "bandcamp",//bandcamp, imgLink, youtube
        albumNo: "1749212330",
        filter: "mus"
    },
//2012
    {
        itemTitle: "Jack Davies Big Band",
        artist: "Jack Davies",
        itemDate: 201201,//year-month
        type: "imgLink",//bandcamp, imgLink, youtube
        link: "https://open.spotify.com/album/4QoK68KFqLSO8eSEqmYPNo",
        img: "JackDaviesBigBand",
        filter: "mus"
    },
//2011
    {
        itemTitle: "Emergence",
        artist: "TSYJO",
        itemDate: 201101,//year-month
        type: "imgLink",//bandcamp, imgLink, youtube
        link: "https://snjo.co.uk/shop/emergence",
        img: "Emergence",
        filter: "mus"
    },  
//2007
    {
        itemTitle: "Exploration",
        artist: "TSYJO",
        itemDate: 200701,//year-month
        type: "imgLink",//bandcamp, imgLink, youtube
        link: "https://snjo.co.uk/shop/exploration",
        img: "Exploration",
        filter: "mus"
    },
]
export default discog



// TEMPLATE
// {
//     itemTitle: "",
//     itemDate: "",//year-month
//     type: "",//bandcamp, imgLink, youtube
//     link: "",
//     albumNo: "",
//     text: "",
// },