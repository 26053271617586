import React from "react"
import {Routes ,Route } from 'react-router-dom';
import Home from './Main/Pages/Home'
import Work from './Main/Pages/Work'
import About from "./Main/Pages/About";
import Projects from "./Main/Pages/Projects";
import Writing from "./Main/Pages/Writing";
import Contact from "./Main/Pages/Contact";

function Main() {
    return (
        <Routes>
            <Route path="/" element={
                <Home />
            }
            />
            <Route path="/about" element={
                <About/>
            }
            />
            <Route path="/work" element={
                <Work />
            }
            />
            <Route path="/projects" element={
                <Projects />
            }
            />
            <Route path="/writing" element={
                <Writing />
            }
            />
            <Route path="/shop" element={
                <main>
                    <h1>SHOP</h1>
                </main>
            }
            />
            <Route path="/contact" element={
                <Contact/>
            }
            />
        </Routes>
    )
}

export default Main