import React, {useContext} from "react"
import { AppContext } from "../../../appContext"
import writing from "../../../Data/Writing"
import FullWidthH1 from "../../Generic/FullWidthH1"
import TextInterpreter from "../MainElements/TextInterpreter"

function Writing() {
    const {appState} = useContext(AppContext)
    const title = appState.lang === "eng" ? writing.titleEng : writing.titleCym
    const textData = appState.lang === "eng" ? writing.contentEng : writing.contentCym

    return(
        <main>
            <FullWidthH1 title={title}/>
            <TextInterpreter items={textData} />
        </main>
    )
}

export default Writing