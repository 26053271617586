import React from "react"

function SqImgLink(props) {
    const link = props.link
    const src = `./Images/${props.src}`
    const alt = props.alt
    const dim = props.dim
    const style = {
        width: `${dim}px`,
        height: `${dim}px`
    }
    console.log(src)
    
    return(
        <div className="iconLink" style={style}>
            <a href={link}>
                <img src={src} alt={alt} style = {style}/>
            </a>
        </div>
    )
}

export default SqImgLink 