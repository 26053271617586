import React from "react"
import {Link} from "react-router-dom";

function NavItem(props) {
    const navItemClass = props.visible ? "navItem" : "hidden"
    
    return(
        <nav className={navItemClass} >
            <Link 
                to={props.link} 
                onClick={(event) => props.toggle()}
            >
                {props.text}
            </Link>
        </nav>
    )
}

export default NavItem