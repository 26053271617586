const writing = {
    titleEng: "Writing",
    titleCym: "Ysgrifennu",
    contentEng: [
        {
            type: "h3",
            title: "Book Chapters"
        },
        {
            type: "p",
            paragraph: "Wright, J. (2021), Concepts for the design of accessible music technology In: Hepworth-Sawyer R., Paterson J., and Toulson R., eds., Innovation in Music: Future Opportunities, chap. 14. London: Routledge, 1st edn."
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), Interaction-Congruence in the Design of Exploratory Sonic Play Instruments With Young People on the Autistic Spectrum. In: Hepworth-Sawyer R., Hodgson J., Paterson J., and Toulson R., eds., Innovation in Music: Performance, Production, Technology, and Business, chap. 19. London: Routledge, 1st edn."  
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), In Search of Non-Verbal Child-Led Music With Young People on the Autistic Spectrum (French Translation). In: Boulanger S., Jacquet C., Balgiu A., Debize, C., eds., SNA — SorcièreNuAnonyme, Dijon, Les presses du réel, 1st edn."  
        },
        {
            type: "h3",
            title: "Papers"

        },
        {
            type: "p",
            paragraph: "Wright, J. (2020), The Appropriation and Utility of Constrained ADMIs. In: NIME'20 Pro- ceedings of the 2020 Conference on New Interfaces for Musical Expression. Birmingham: Royal Birmingham Conservatoire."  
        },
        {
            type: "p",
            paragraph: "Wright, J., and Dooley, J. (2019), On the Inclusivity of Constraint: Creative Appropriation in Instruments for Neurodiverse Children and Young People. In: NIME'19 Proceedings of the 2019 Conference on New Interfaces for Musical Expression. Porto Alegre: Universidade Federal do Rio Grande do Sul."  
        },
        {
            type: "h3",
            title: "PhD Thesis"
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), The Design of Exploratory Sonic-Play Instruments With Non-Verbal Young People on the Autistic Spectrum (Ph.D. thesis). Royal Birmingham Conservatoire, Birmingham City University, Birmingham, UK."  
        },
    ],
    contentCym: [
        {
            type: "h3",
            title: "Penodau Llyfrau"
        },
        {
            type: "p",
            paragraph: "Wright, J. (2021), Concepts for the design of accessible music technology In: Hepworth-Sawyer R., Paterson J., and Toulson R., eds., Innovation in Music: Future Opportunities, chap. 14. London: Routledge, 1st edn."
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), Interaction-Congruence in the Design of Exploratory Sonic Play Instruments With Young People on the Autistic Spectrum. In: Hepworth-Sawyer R., Hodgson J., Paterson J., and Toulson R., eds., Innovation in Music: Performance, Production, Technology, and Business, chap. 19. London: Routledge, 1st edn."  
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), In Search of Non-Verbal Child-Led Music With Young People on the Autistic Spectrum (French Translation). In: Boulanger S., Jacquet C., Balgiu A., Debize, C., eds., SNA — SorcièreNuAnonyme, Dijon, Les presses du réel, 1st edn."  
        },
        {
            type: "h3",
            title: "Papurau"

        },
        {
            type: "p",
            paragraph: "Wright, J. (2020), The Appropriation and Utility of Constrained ADMIs. In: NIME'20 Pro- ceedings of the 2020 Conference on New Interfaces for Musical Expression. Birmingham: Royal Birmingham Conservatoire."  
        },
        {
            type: "p",
            paragraph: "Wright, J., and Dooley, J. (2019), On the Inclusivity of Constraint: Creative Appropriation in Instruments for Neurodiverse Children and Young People. In: NIME'19 Proceedings of the 2019 Conference on New Interfaces for Musical Expression. Porto Alegre: Universidade Federal do Rio Grande do Sul."  
        },
        {
            type: "h3",
            title: "Traethawd PhD"
        },
        {
            type: "p",
            paragraph: "Wright, J. (2019), The Design of Exploratory Sonic-Play Instruments With Non-Verbal Young People on the Autistic Spectrum (Ph.D. thesis). Royal Birmingham Conservatoire, Birmingham City University, Birmingham, UK."  
        },
    ]
}

export default writing