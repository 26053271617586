import React from "react"

function Hamburger(props) {
    const anim = props.first ? "" : "Anim";
    const nameMod = props.expanded ? "xham" : `ham${anim}`

    return(
        <button 
            className="hamburgerButton"
            onClick={(event) => {props.toggle()}}
        >
            <div className="hamburger">
                <div className="hamburgerSq">
                    <div className = {`${nameMod}1`}></div>
                    <div className = {`${nameMod}2`}></div>
                    <div className = {`${nameMod}3`}></div>
                </div>
            </div>
        </button>
    )
}

export default Hamburger