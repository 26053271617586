const aboutData = {
    titleEng: "About",
    titleCym: "Amdanaf",
    contentEng: [
        {  
            type: "p", 
            paragraph: "Joe Wright is a musician and maker based in Cardiff, with an interest in improvised/experimental music making, accessible music technology/practice, and creative code."
        },
        {
            type: "h3",
            title: "Music"
        },
        {   
            type: "p",
            paragraph: "As a saxophonist, Joe is currently playing across the UK with jazz/contemporary music groups led by Rob Luft, Corrie Dick, and as a collaborator in FORJ, with Jonathan Silk, Nick Jurd and Josh Arcoleo. He also has a long-standing collaboration - Onin - with experimental musician, James L Malone that explores unstable systems and atypical interactions. Joe is also involved in re/mixing music with some of the groups above, extending the creative process beyond the moment of performance, and allowing him to draw on his passion for exploring sonic textures and timbres. In all of these cases, the process of musical co-production is the main draw for Joe, whose over-arching aim is to find musical spaces that are unique to a particular group of people."
        },
        {
            type: "h3",
            title: "Accessible Music and Tech"
        },
        {
            type: "p",
            paragraph: "After graduating from the Royal Academy of Music in 2011, Joe was fortunate to work for the pioneering Oily Cart theatre company, who specialise in making sensory, interactive theatre work for all kinds of young people. From there, Joe discovered a passion for work in this field, not just as a means for making high-quality musical experiences available to wider audiences, but also as another means of creating unique musical spaces, interactions and collaborations. He has since continued to work in this field, on projects including the inclusive sound/movement piece 'Under Foot', on Ellie Griffiths' 'Sound Symphony', and on recent Oily Cart productions, 'Jamboree' and 'Space To Be'. After completing his PhD on designing inclusive sonic play instruments in 2019, he has also been involved in making sound-makers and instrumets with companies such as Oily Cart, and most recently with Drake Music and OrchLab."
        }
    ],
    contentCym: [
        {
            type: "p", 
            paragraph: "Mae Joe Wright yn gerddor a gwneuthurwr wedi'i leoli yng Nghaerdydd, efo diddordeb mewn creu cerddoriaeth fyrfyfyr / arbrofol, technoleg/ymarfer cerddoriaeth hygyrch, a chod creadigol."
        },
        {
            type: "h3",
            title: "Cerddoriaeth"
        },
        {   
            type: "p",
            paragraph: "Fel sacsoffonydd, mae Joe ar hyn o bryd yn chwarae ledled y DU efo grwpiau jazz / cerddoriaeth gyfoes dan arweiniad Rob Luft, Corrie Dick ac fel cydweithredwr yn FORJ, efo Jonathan Silk, Nick Jurd a Josh Arcoleo. Mae ganddo hefyd gydweithrediad hirsefydlog - Onin - efo'r cerddor arbrofol, James L Malone sy'n archwilio systemau ansefydlog a rhyngweithio annodweddiadol. Mae Joe hefyd yn ymwneud â chymysgu/ail-gymysgu cerddoriaeth efo rhai o'r grwpiau uchod, gan ymestyn y broses greadigol y tu hwnt i foment y perfformiad, a chaniatáu iddo dynnu ar ei angerdd am archwilio gweadau a rhinweddau sonig. Ym mhob un o'r achosion hyn, y broses o gyd-gynhyrchu cerddorol yw'r brif atyniad i Joe, a'i nod cyffredinol yw dod o hyd i ofodau cerddorol sy'n unigryw i grŵp penodol o bobl."
        },
        {
            type: "h3",
            title: "Cerddoriaeth a Thechnoleg Hygyrch"
        },
        {
            type: "p",
            paragraph: "Ar ôl graddio o'r Academi Gerdd Frenhinol yn 2011, bu Joe yn ffodus i weithio i'r cwmni theatr arloesol Oily Cart, sy'n arbenigo mewn gwneud gwaith theatr synhwyraidd, rhyngweithiol i bob math o bobl ifanc. O'r fan honno, darganfyddodd Joe angerdd am waith yn y maes hwn, nid yn unig fel modd i sicrhau bod profiadau cerddorol o ansawdd uchel ar gael i gynulleidfaoedd ehangach, ond hefyd fel ffordd arall o greu gofodau cerddorol unigryw, rhyngweithio a chydweithio. Mae wedi parhau i weithio yn y maes hwn ar brosiectau gan gynnwys y darn sain / symud cynhwysol 'Under Foot', ar 'Sound Symphony' Ellie Griffiths, ac ar gynyrchiadau diweddar Oily Cart, 'Jamboree' a 'Space To Be'. Ar ôl cwblhau ei PhD ar ddylunio offerynnau chwarae sonig cynhwysol yn 2019, mae hefyd wedi bod yn ymwneud â chynhyrchu gwneuthurwyr sain ac offerynnau efo cwmnïau fel Oily Cart, ac yn fwy diweddar efo Drake Music ac OrchLab."


        }
    ]

}

export default aboutData