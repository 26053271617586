import React, {useContext} from "react"
import { AppContext } from "../../../appContext"
import projects from "../../../Data/Projects"
import FullWidthH1 from "../../Generic/FullWidthH1"
import TextInterpreter from "../MainElements/TextInterpreter"

function Projects() {
    const {appState} = useContext(AppContext)
    const title = appState.lang === "eng" ? projects.titleEng : projects.titleCym
    const textData = appState.lang === "eng" ? projects.contentEng : projects.contentCym

    return(
        <main>
            <FullWidthH1 title={title}/>
            <TextInterpreter items={textData} />
        </main>
    )
}

export default Projects