import React from "react"

function FullWidthH1(props){
    return(
        <div className="fullWidthH1">
            <h1>
                {props.title}
            </h1>
        </div>
    )
}

export default FullWidthH1