import React, {useContext} from "react"
import NavItem from "../NavItem"
import { AppContext } from "../../../appContext"
import NavBarData from "../../../Data/NavBarData"

function DesktopNavItems(props) {
    const {appState} = useContext(AppContext) 
    const navItems = NavBarData.map( (item) => {
        if (item.itemEng !== "home"){
            return(
                <NavItem
                    key={item.itemEng}
                    text={appState.lang==="eng" ? item.itemEng : item.itemCym}
                    link={item.link}
                    visible = {true}
                    toggle={() => {}}
                />
            )
        }
        else {
            return (null)
        }
    })

    return(
        <div className="deskNavItems">
            {navItems}
        </div>
    )
}

export default DesktopNavItems