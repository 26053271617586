import React from "react"

function ContentSq(props) {

    const style1a = {
        borderTopRightRadius: `${3+ Math.random()*10}vw ${1+Math.random()*1000}vh`,
        borderTopLeftRadius: `${1+ Math.random()*1000}vw ${3+Math.random()*10}vh`,
        borderBottomRightRadius: `${3+ Math.random()*10}vw ${1+Math.random()*1000}vh`,
        borderBottomLeftRadius: `${1+ Math.random()*1000}vw ${3+Math.random()*10}vh`,
        borderTop: `${1 + Math.random()*5}px solid black`,
        borderBottom: `${1 + Math.random()*5}px solid black`,
        borderLeft: `${1 + Math.random()*5}px solid black`,
        borderRight: `${1 + Math.random()*5}px solid black`,
        width: props.dim,
        height: props.dim
    }
    const style1b = {
        borderTopRightRadius: `${1+Math.random()*1000}vw ${3+Math.random()*10}vh`,
        borderTopLeftRadius: `${3+Math.random()*10}vw ${1+Math.random()*1000}vh`,
        borderBottomRightRadius: `${1+Math.random()*1000}vw ${3+Math.random()*10}vh`,
        borderBottomLeftRadius: `${3+Math.random()*10}vw ${1+Math.random()*1000}vh`,
        borderTop: `${1 + Math.random()*5}px solid black`,
        borderBottom: `${1 + Math.random()*5}px solid black`,
        borderLeft: `${1 + Math.random()*5}px solid black`,
        borderRight: `${1 + Math.random()*5}px solid black`,
        width: props.dim,
        height: props.dim
    }
    const style3 = {
        borderTopRightRadius: `${200+Math.random()*800}vh ${5+Math.random()*2}vw`,
        borderTopLeftRadius: `${5+Math.random()*2}vw ${200+Math.random()*800}vh`,
        borderBottomLeftRadius: `${5+Math.random()*2}vw ${200+Math.random()*800}vh`,
        borderLeft: `${2 + Math.random()*10}px solid black`,
        borderTop: `${2 + Math.random()*10}px solid black`,
        width: props.dim,
        height: props.dim
    }
    const style4={
        border: "none",
        width: props.dim,
        height: props.dim
    }
    let style = Math.floor(Math.random *2) === 1 ? style1a : style1b
    style = props.leftBorder ? style3 : style
    style = props.hideBorder ? style4 : style
    const style2 = {
        width: props.dim+8,
        height: props.dim+8
    }
    return (
        <div className="contentSqOuter" style={style2}>
            <div className="contentSq" style={style}>
                {props.children}
            </div>
        </div>
    )
}

export default ContentSq