import React, {useContext} from "react"
import { AppContext } from "../../../appContext"
import { useLocation } from 'react-router-dom';

function BackgroundImgs() {
    const {appState} = useContext(AppContext)
    const size = appState.imgsLoaded ? "/Large/" : "/Small/"
    const path = "./Images/HomeImgs" + size 
    
    let img1 = Math.floor(Math.random() * 20)
    let img2 = Math.floor(Math.random() * 20)
    while(img1===img2){
        img2 = Math.floor(Math.random() * 20)
    }

    img1 = `${img1}.jpg`
    img2 = `${img2}.jpg`
    


  let classExt
  const location = useLocation().pathname
  classExt = location==="/" ? "fadeIn" : "fadeOut"
  
    return(
        <div className={"homeImgContainer " + classExt} >
            <img src={path + img1} alt="" className={"homeImg1"} />
            <img src={path + img2} alt="" className={"homeImg2"} />
        </div>
    )
}

export default BackgroundImgs