import React from "react"

function Copyright(props){
    const date = new Date();
    const year = date.getFullYear();

    return(
        <div className="copyright">
            <p>
                &#169; {year}&nbsp;Joe Wright<br/>
                Web design by Joe Wright
            </p>
        </div>
    )
}

export default Copyright