import React, {useContext} from "react"
import { AppContext } from "../appContext"
import DesktopNavBar from "./NavBar/DesktopNavBar"
import MobileNavBar from "./NavBar/MobileNavBar"

function Header(props) {
const {appState} = useContext(AppContext)
const whichMenu = appState.isMobileView ? <MobileNavBar /> : <DesktopNavBar/>
return(
     <header>
         {whichMenu}
     </header>
 )
}

export default Header