const contact = {
    contentEng: [
        {
            type: "h3C",
            title: "email"
        },
        {
            type: "pC",
            paragraph: "You can contact joe via email at: joe [at] joewrightmusic [dot] co [dot] uk"
        },
        {
            type: "h3C",
            title: "Social Media"
        },
    ],
    contentCym: [
        {
            type: "h3C",
            title: "ebost"
        },
        {
            type: "pC",
            paragraph: "Gallwch gysylltu â Joe trwy'r e-bost yma: joe [at] joewrightmusic [dot] co [dot] uk"
        },
        {
            type: "h3C",
            title: "Cyfryngau cymdeithasol"
        },
        
    ]
}

export default contact