import React from "react"

function ImgLink(props) {
    const link = props.link
    const icon = props.icon
    const alt = props.alt
    
    return(
        <div className="iconLink">
            <a href={link}>
                <img src={icon} alt={alt}/>
            </a>
        </div>
    )
}

export default ImgLink 