import React from "react"

function SqH2(props) {
    const title = props.title
    const dim = props.dim
    const style = {
        width: `${dim}px`,
        height: `${dim}px`
    }
    
    return(
        <div className="sqH2" style={style}>
            <h2>{title}</h2>
        </div>
    )
}

export default SqH2 