import React from "react" 
import Copyright from "./Footer/Copyright"
import SocialLinks from "./Footer/SocialLinks"
import MailingList from "./Footer/MailingList"
function Footer(props){
    return(
        <footer>
            <Copyright/>
            <SocialLinks />
            {/* <MailingList /> */}
        </footer>
    )
}

export default Footer