import React from "react"
import NavTitle from "./NavTitle"
import DesktopNavItems from "./Desktop/DesktopNavItems"
import DesktopLang from "./Desktop/DesktopLang"

function DesktopNavBar(props) {
    
    function dummyToggle(){
        return 0
    }

    return(
        <nav className="deskNavBar">
            <NavTitle isMobile={false} toggle={dummyToggle}/>
            <DesktopNavItems visible={true} toggle={dummyToggle} />
            <DesktopLang />
        </nav>
    )
}

export default DesktopNavBar