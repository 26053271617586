import React from "react"

function Youtube(props) {
    const video = props.video;
    const dim = props.dim
    return(
        <iframe 
            width={`${dim}px`}
            height={`${dim}px`}
            src={`https://www.youtube.com/embed/${video}`}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            >
        </iframe>
    )
}

export default Youtube