import React, {createContext, useState, useEffect} from "react"
import pictures from "./Data/homeImgs"


const AppContext = createContext()




function AppContextProvider(props) {
    const [appState, setAppState] = useState({
        inWidth: 0,
        inHeight: 0,
        isMobieView: true, 
        isPortrait: true,
        lang: "eng",
        imgCount: 0,
        imgsLoaded: false,
    }) 

    
    
    function flipLang(){
        setAppState( prevAppState => {
            return {
                ...prevAppState,
                lang: prevAppState.lang==="eng" ? "cym" : "eng" 
            }
        })
    }

    //equivalent to componentDidMount
    useEffect(() => {
        function getSizes(props) {
            document.getElementById("root").style.height = `${window.innerHeight}px`
            setAppState(prevState => {
                return {
                    ...prevState,
                    inWidth: window.innerWidth,
                    inHeight: window.innerHeight,
                    isMobileView: window.innerWidth < 815, 
                    isPortrait: window.innerHeight>=window.innerWidth
                }
            })
        }
        function preloadImgs(){
            pictures.forEach((picture) => {
              const img = new Image();
              img.onload = setAppState(prevState => {
                let tempCount = prevState.imgCount + 1
                let done = tempCount===pictures.length ? true : false  
                return{
                  ...prevState,
                  imgCount: tempCount,
                  imgsLoaded: done
              }})
              img.src = `../public/Images/HomeImgs/Large/${picture}`;
            });
        }
        
        //runs on first render ...
        function doAfterResize() {
            getSizes()
        }
        var timeId = null
        window.addEventListener('resize', () => {
            clearTimeout(timeId)
            timeId = setTimeout(doAfterResize, 400)
        }

        )
        getSizes()
        preloadImgs()
        //called on component unmount...
        return () => {
            window.removeEventListener('resize', getSizes())
        }
    }, [])
    
    
        return ( 
            <AppContext.Provider 
            value={{
                appState: appState, 
                flipLang: flipLang,
            }}
            >
                {props.children}
            </AppContext.Provider>
        )
}

export {AppContextProvider, AppContext}