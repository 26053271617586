import React, {useContext} from "react"
import { AppContext } from "../../../appContext"

function DesktopLang(props) {
    const {appState, flipLang} = useContext(AppContext)
    const flipLangName = appState.lang==="eng" ? "[cymraeg]" : "[english]" 
    return(
        <div className="deskLang">
            <button 
                className="langButton"
                onClick = {() => flipLang()}
            >
                <p>{flipLangName}</p>
            </button>
        </div>
    )
}

export default DesktopLang