import React, {useContext} from "react"
import { AppContext } from "../../../appContext"
import about from "../../../Data/About"
import FullWidthH1 from "../../Generic/FullWidthH1"
import TextInterpreter from "../MainElements/TextInterpreter"

function About() {
    const {appState} = useContext(AppContext)
    const title = appState.lang === "eng" ? about.titleEng : about.titleCym
    const textData = appState.lang === "eng" ? about.contentEng : about.contentCym

    return(
        <main>
            <FullWidthH1 title={title}/>
            <TextInterpreter items={textData} />
        </main>
    )
}

export default About