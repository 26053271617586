const navBarData = [
    {
        itemEng: "home",
        itemCym: "hafan",
        link: "/"
    },
    {
        itemEng: "about",
        itemCym: "amdanaf",
        link: "/about"
    },
    {
        itemEng: "work",
        itemCym: "gwaith",
        link: "/work"
    },
    {
        itemEng: "projects",
        itemCym: "prosiectau",
        link: "/projects"
    },
    {
        itemEng: "writing",
        itemCym: "ysgrifennu",
        link: "/writing"
    },
    // {
    //     itemEng: "shop",
    //     itemCym: "siop",
    //     link: "/shop"
    // },
    {
        itemEng: "contact",
        itemCym: "cysylltu",
        link: "/contact"
    },
]

export default navBarData


  // {
    //     itemEng: "news",
    //     itemCym: "newyddion",
    //     link: "/news"
    // },
    // {
    //     itemEng: "photos",
    //     itemCym: "lluniau",
    //     link: "/photos"
    // },