import React from "react"
import ImgLink from "../Generic/ImgLink";

function SocialLinks(props){
    

    return(
        <div className="socialLinks">
            <ImgLink 
                icon = "./Images/Icons/bandcamp.png"
                alt = "link to Joe Wright's GitHub"
                link = "https://joewrightmusic.bandcamp.com/"
            />
            <ImgLink 
                icon = "./Images/Icons/youtube.png"
                alt = "link to Joe Wright's GitHub"
                link = "https://www.youtube.com/channel/UCpQHdpMEKhmSGxpujVIGogw"
            />
            {/* <ImgLink 
                icon = "./Images/Icons/twitter.png"
                alt = "link to Joe Wright's Twitter"
                link = "https://twitter.com/joewright_music"
            /> */}
            <ImgLink 
                icon = "./Images/Icons/github.png"
                alt = "link to Joe Wright's GitHub"
                link = "https://github.com/JoeWrightMusic"
            />
            {/* <ImgLink 
               icon = "./Images/Icons/fb.png"
               alt = "link to Joe Wright's Facebook Page"
               link = "https://www.facebook.com/profile.php?id=61559642041569"
           /> */}
            <ImgLink 
               icon = "./Images/Icons/instagram.png"
               alt = "link to Joe Wright's Instagram"
               link = "https://www.instagram.com/joewrightmusic/"
           />
        </div>
    )
}

export default SocialLinks