import React, {useContext} from "react"
import { AppContext } from "../../../appContext"
import FullWidthH1 from "../../Generic/FullWidthH1"
import contact from "../../../Data/Contact"
import TextInterpreter from "../MainElements/TextInterpreter"
import SocialLinks2 from "../../Footer/SocialLinks2"

function Contact() {
    const {appState} = useContext(AppContext)
    const title = appState.lang === "eng" ? "Contact" : "Cysylltu"
    const textData = appState.lang === "eng" ? contact.contentEng : contact.contentCym

    return(
        <main>
            <FullWidthH1 title={title}/>
            <TextInterpreter items={textData} />
            <SocialLinks2 />
        </main>
    )
}

export default Contact