import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Main from "./Components/Main"
import Footer from "./Components/Footer"
import Header from "./Components/Header"
import BackgroundImgs from "./Components/Main/MainElements/BackgroundImgs";

function App() {

    

  return (
    <Router>
      <BackgroundImgs />
      <Header />
      <Main />
      <div className="fillEmptySpace"></div>
      <Footer />
    </Router>
  );
}


export default App;
