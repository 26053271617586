import React from "react"

function BandcampLong(props) {
    const albumNo = props.albumNo
    const dim = props.dim
    return(
        <iframe 
            title={albumNo}
            style={{border: "0", width: "300px", height: "42px", maxWidth: "none"}} 
            src={`https://bandcamp.com/EmbeddedPlayer/album=${albumNo}/size=small/bgcol=ffffff/linkcol=000000/transparent=true/`}
        seamless>
        </iframe>
        
    )
}

export default BandcampLong